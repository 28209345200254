.Footer {
  background: #333;
  color: #fff;
  display: flex;
  padding: 1rem 2rem 7.2rem 2rem;
  position: relative;
  transition: padding-bottom 0.25s ease-in-out;
  width: 100%;
  z-index: 4;

  a {
    color: #fff;
  }
}

.Footer__left {
  flex: 1 1 auto;

  a:hover {
    text-decoration: underline;
  }
}

.Footer__version {
  color: #777;
  margin-left: 1rem;
}

.Footer__right {
  flex: 0 0 auto;

  a {
    display: inline-block;
    margin-left: 1rem;
    margin-top: 0.4rem;
  }

  img {
    width: 2rem;
    height: 2rem;
  }
}

.Footer__left__top {
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
}

.Footer__left__bottom {
  font-size: 1.1rem;
}

.slash {
  &:after {
    color: $color-gray;
    content: '/';
    display: inline-block;
    font-size: 2em;
    font-weight: 100;
    padding: 0 0.75rem;
    position: relative;
    top: 0.1em;
  }
}

@media screen and (min-width: $screen-md) {
  body.has-open-tracklist .Footer {
    padding-bottom: 24.4rem;
  }
}

@media screen and (max-width: $screen-sm) {
  .Footer {
    padding: 1rem 1rem 4.9rem 1rem;
  }

  body.has-waveform .Footer {
    padding-bottom: 8.9rem;
  }
}

.has-player {
  padding-bottom: 8.9rem;
}
