// Styles for ProtonRadioCardCarousel, overrides many of the default styles from the Splide component.

.carousel {
  margin-bottom: 1rem !important;
  position: initial !important;

  @media screen and (min-width: 822px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 4rem 1rem 4rem;
  }
}

.carousel__track {
  overflow: visible !important;

  @media screen and (min-width: 822px) {
    overflow: hidden !important;
  }
}

.carousel__card {
  width: 100vw !important;

  @media screen and (min-width: 822px) {
    width: 540px !important;
    height: auto;
    overflow: visible;
  }
}

.splide__pagination {
  @media screen and (max-width: 822px) {
    position: relative !important;
    bottom: 44% !important;
    left: 42% !important;
    background: #f0f1f2;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 28px;
    width: 116px;
    padding-bottom: 5px !important;
  }

  @media screen and (max-width: 492px) {
    bottom: 32.5% !important;
    left: 40% !important;
  }

  @media screen and (max-width: 416px) {
    bottom: 30% !important;
    left: 34% !important;
  }

  @media screen and (max-width: 293px) {
    bottom: 28% !important;
    left: 32% !important;
  }

  @media screen and (max-width: 247px) {
    bottom: 25% !important;
    left: 29% !important;
  }

  @media screen and (max-width: 233px) {
    bottom: 23% !important;
    left: 27% !important;
  }
}

.splide__arrows {
  position: absolute;
  top: 60%;
  right: 0;
  left: 0;

  @media screen and (max-width: 822px) {
    position: relative;
    top: 90px;
    width: calc(100vw + 60px);
    left: -30px;
  }

  @media screen and (max-width: 492px) {
    position: relative;
    top: 120px;
  }
}

.splide__arrow {
  background: none !important;
}

.splide__arrow svg {
  fill: $color-gray--light !important;
}

.splide__arrow--prev {
  @media screen and (max-width: 822px) {
    width: 60px !important;
    height: 80px !important;
  }
}

.splide__arrow--next {
  @media screen and (max-width: 822px) {
    width: 60px !important;
    height: 80px !important;
  }
}

.hide-left-arrow .splide__arrow--prev {
  display: none;
}

.hide-right-arrow .splide__arrow--next {
  display: none;
}
